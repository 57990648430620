import {useSelector} from 'react-redux'

import {
  getBrand,
  getBrandCode,
  getBrandName,
  getBrandShowSignIn,
  getBrandTheme,
  getIsBrandAdvancedLevel,
  getIsBrandBasicLevel,
  getIsBrandCustomLevel,
  getIsBrandDealbase,
  getIsBrandKiwi,
  getIsBrandOffersLockedByDefault,
  getIsBrandVio,
  getIsCookieProEnabledForBrand
} from '../modules/selectors'

/**
 * A helper hook to get the most commonly used brand properties from the store.
 */
export const useBrand = () => {
  const brand = useSelector(getBrand)
  const brandCode = useSelector(getBrandCode)
  const brandLevelIsBasic = useSelector(getIsBrandBasicLevel)
  const brandLevelIsAdvanced = useSelector(getIsBrandAdvancedLevel)
  const brandLevelIsCustom = useSelector(getIsBrandCustomLevel)
  const brandOffersLockedByDefault = useSelector(
    getIsBrandOffersLockedByDefault
  )
  const brandShowSignIn = useSelector(getBrandShowSignIn)
  const brandIsVio = useSelector(getIsBrandVio)
  const brandIsKiwi = useSelector(getIsBrandKiwi)
  const brandIsDealbase = useSelector(getIsBrandDealbase)
  const brandShareUrl = brand.shareUrl
  const brandPrivateDealMessage = brand.privateDealMessage
  const brandPageTitleMessage = brand.pageTitleMessage
  const brandPageTitleTagLineMessage = brand.pageTitleTagLineMessage
  const brandHomepageHeadingMessage = brand.homepageHeadingMessage
  const brandHomepageSubheadingMessage = brand.homepageSubheadingMessage
  const brandIsInternal = brand.isInternal
  const brandMainHotelImageIndex = brand.mainHotelImageIndex
  const brandEnabledFilters = brand.enabledFilters
  const {bookOnBrandEnabled} = brand
  const brandIsCookieProEnabled = useSelector(getIsCookieProEnabledForBrand)
  const brandThemeName = useSelector(getBrandTheme)
  const brandName = useSelector(getBrandName)

  return {
    brand,
    brandCode,
    brandName,
    brandLevelIsBasic,
    brandLevelIsAdvanced,
    brandLevelIsCustom,
    brandOffersLockedByDefault,
    brandIsVio,
    brandIsKiwi,
    brandIsDealbase,
    brandShareUrl,
    brandPrivateDealMessage,
    brandPageTitleMessage,
    brandPageTitleTagLineMessage,
    brandHomepageHeadingMessage,
    brandHomepageSubheadingMessage,
    brandShowSignIn,
    brandIsInternal,
    brandMainHotelImageIndex,
    brandEnabledFilters,
    bookOnBrandEnabled,
    brandIsCookieProEnabled,
    brandThemeName
  }
}

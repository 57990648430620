import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import noop from 'lodash/noop'

import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  CookieConsentContext,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {parseQueryString} from '@daedalus/core/src/utils/url'

export enum CookieBannerProClickComponent {
  AcceptButton = 'AcceptButton',
  RejectButton = 'RejectButton',
  PreferenceCenter = 'PreferenceCenter',
  CloseButton = 'CloseButton',
  AppPage = 'AppPage'
}

const getCookieProConsentId = (): string => {
  const cookieProConsent = getCookie('OptanonConsent') ?? ''
  const {consentId} = parseQueryString(cookieProConsent)

  return consentId as string
}

const getEnabledCookieGroups = (): string[] => {
  if (typeof window === 'undefined') return []
  const onetrustActiveGroups = window.OnetrustActiveGroups || ''

  return onetrustActiveGroups.split(',').filter(Boolean)
}

const getCookieConsentContext = (): CookieConsentContext => ({
  consentId: getCookieProConsentId(),
  enabledCookieGroups: getEnabledCookieGroups()
})

export const useCookiePro = (onAccept: () => void = noop) => {
  const dispatch = useDispatch()
  const [isPreferencesCenterVisible, setIsPreferencesCenterVisible] =
    useState(false)
  const dispatchTeamEvent = useDispatchTeamEvent()
  const trackBannerSubmitted = useCallback(
    (component?: CookieBannerProClickComponent) => {
      const cookieConsentContext = getCookieConsentContext()

      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.CookieBanner,
          action: Action.Submitted,
          analyticsContext: {
            [AnalyticsContext.CookieConsentContext]: cookieConsentContext
          },
          component
        })
      )
    },
    [dispatch]
  )

  const handlePreferenceCenterAcceptCookies = useCallback(() => {
    onAccept()
    trackBannerSubmitted(CookieBannerProClickComponent.PreferenceCenter)
    setIsPreferencesCenterVisible(false)
  }, [trackBannerSubmitted, onAccept])

  const handlePreferenceCenterClose = useCallback(() => {
    setIsPreferencesCenterVisible(false)
  }, [trackBannerSubmitted, onAccept])

  const openPreferencesCenter = () => {
    // eslint-disable-next-line new-cap
    window.OneTrust?.ToggleInfoDisplay()
    setIsPreferencesCenterVisible(true)
  }

  /**
   * Attach event listeners to cookie preferences center
   */
  useEffect(() => {
    if (!isPreferencesCenterVisible) {
      return
    }

    // according to CookiePro docs this is how they would detect preference center events
    // https://community.cookiepro.com/s/article/UUID-534defd9-7ec8-fc0e-78bf-8dce8c6c5593
    const preferenceCenterAllowAllBtn = document.querySelector(
      '#accept-recommended-btn-handler'
    )

    const preferenceCenterSaveBtn = document.querySelectorAll(
      '.save-preference-btn-handler.onetrust-close-btn-handler'
    )[0]

    const preferenceCenterCloseBtn = document.querySelector(
      '#close-pc-btn-handler'
    )

    preferenceCenterAllowAllBtn?.addEventListener(
      'click',
      handlePreferenceCenterAcceptCookies
    )

    preferenceCenterSaveBtn?.addEventListener(
      'click',
      handlePreferenceCenterAcceptCookies
    )

    preferenceCenterCloseBtn?.addEventListener(
      'click',
      handlePreferenceCenterClose
    )

    return () => {
      preferenceCenterAllowAllBtn?.removeEventListener(
        'click',
        handlePreferenceCenterAcceptCookies
      )

      preferenceCenterSaveBtn?.removeEventListener(
        'click',
        handlePreferenceCenterAcceptCookies
      )

      preferenceCenterCloseBtn?.addEventListener(
        'click',
        handlePreferenceCenterClose
      )
    }
  }, [isPreferencesCenterVisible, handlePreferenceCenterAcceptCookies])

  return {
    trackBannerSubmitted,
    openPreferencesCenter,
    cookieConsentContext: getCookieConsentContext(),
    isPreferencesCenterVisible
  }
}

import React from 'react'

declare global {
  interface Window {
    OnetrustActiveGroups: string
    COOKIE_PRO_LOADED: boolean
    OTExternalConsent?: string
    OneTrust: {
      AllowAll: () => void
      RejectAll: () => void
      ToggleInfoDisplay: () => void
      getGeolocationData: () => {country: string; state: string}
      changeLanguage: (lang: string) => void
    }
  }
}

const GDPR_COUNTRIES = new Set([
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK'
])

/**
 * Uses the `OneTrust` geolocation API to determine the user location data
 *
 * @returns The user country and state, or null if the sdk is still loading
 */
const getGeolocationData = () => {
  if (typeof window === 'undefined') return null

  const geolocationData = window.OneTrust?.getGeolocationData()

  if (!geolocationData) {
    return null
  }

  return geolocationData
}

/**
 * Uses the `OneTrust` geolocation API to determine the user country and state.
 * If the SDK is still loading, it will listen to the load event and updates
 * with the correct value as soon as it is available.
 *
 * @returns The user country and state, or null if the sdk is still loading
 */
const useGeolocationData = () => {
  const [geolocationData, setGeolocationData] =
    React.useState(getGeolocationData)

  const updateGeolocationData = () => {
    setGeolocationData(getGeolocationData)
  }

  React.useEffect(() => {
    if (geolocationData) return

    window.addEventListener('cookieProLoaded', updateGeolocationData)

    return () => {
      window.removeEventListener('cookieProLoaded', updateGeolocationData)
    }
  }, [geolocationData])

  return geolocationData
}

/**
 * Uses the OneTrust geolocation API to determine if the user country
 * needs to comply with GDPR.
 *
 * @returns boolean that indicates if the country must comply with GDRP,
 * or null if the SDK is still loading.
 */
export const useIsGDPRCountry = (): boolean | null => {
  const geolocationData = useGeolocationData()

  if (!geolocationData) return null

  return GDPR_COUNTRIES.has(geolocationData.country)
}

/**
 * Uses the OneTrust geolocation API to determine if the user state
 * needs to comply with CCPA.
 *
 * @returns boolean that indicates if the country must comply with CCPA,
 * or null if the SDK is still loading.
 */
export const useIsCCPAState = (): boolean | null => {
  const geolocationData = useGeolocationData()

  if (!geolocationData) return null

  return geolocationData.country === 'US' && geolocationData.state === 'CA'
}

import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {AnimatePresence, motion} from 'framer-motion'
import noop from 'lodash/noop'

import {useOnClickOutside} from '@daedalus/atlas/hooks/useOnClickOutside'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {
  fadeInOutAndHide,
  transitions
} from '@daedalus/atlas/utils/framerTransitions'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/components/TeamContext'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {getCookie} from '@daedalus/core/src/_web/utils/cookies'

import {CookieProBanner} from './CookieProBanner'
import {CookieBannerProClickComponent, useCookiePro} from './useCookiePro'

const CookieBannerWrapper = styled(motion.div)(
  ({theme}) => css`
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 101;
    padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s400}px;
    display: flex;
    justify-content: center;
    ${mq.desktopXs(`
      padding: ${theme.layout.spacing.s600}px;
    `)};
  `
)

const shouldCookieBannerBeShown = (): boolean => {
  // if `OptanonAlertBoxClosed` cookie exists then they have interacted with the
  // banner or the cookie is expired and reconsent will occur
  const userHasSavedCookiePreferences = getCookie('OptanonAlertBoxClosed')
  // if CookiePro script fails for some reason (window.OneTrust === undefined) don't show the banner
  const shouldShowBanner = Boolean(
    !userHasSavedCookiePreferences && window.OneTrust
  )

  return shouldShowBanner
}

/**
 * Custom implementation of a GDPR compliant cookie banner. It makes use
 * of the OneTrust SDK, so make sure the SDK scripts are correctly loaded
 * in the HTML template.
 */
export interface CookieBannerProps {
  /**
   * Callback invoked when a user accepts the cookies, either in the
   * banner or the preference center
   */
  onAccept?: (acceptedByUser: boolean) => void
}

export const CookieProBannerContainer = ({
  onAccept: setCookiesAcceptedByUser = noop
}: CookieBannerProps) => {
  const dispatch = useDispatch()
  const {locale} = useIntl()
  const ref = useRef(null)
  const dispatchTeamEvent = useDispatchTeamEvent()
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false)

  const onAcceptCookies = useCallback(() => {
    setIsCookieBannerVisible(false)
    setCookiesAcceptedByUser(true)
  }, [setIsCookieBannerVisible, setCookiesAcceptedByUser])

  const {
    trackBannerSubmitted,
    openPreferencesCenter,
    cookieConsentContext,
    isPreferencesCenterVisible
  } = useCookiePro(onAcceptCookies)

  const handleCookieProScriptLoaded = useCallback(() => {
    setIsCookieBannerVisible(shouldCookieBannerBeShown)
    setCookiesAcceptedByUser(!shouldCookieBannerBeShown)
    dispatchTeamEvent(
      trackEvent({
        category: Category.System,
        entity: Entity.CookieBannerScript,
        action: Action.Loaded
      })
    )
  }, [setIsCookieBannerVisible, dispatch])

  const handleAcceptAllCookies = useCallback(() => {
    // eslint-disable-next-line new-cap
    window.OneTrust?.AllowAll()
    setIsCookieBannerVisible(false)
    setCookiesAcceptedByUser(true)
    trackBannerSubmitted(CookieBannerProClickComponent.AcceptButton)
  }, [setCookiesAcceptedByUser, trackBannerSubmitted])

  const handleRejectAllCookies = useCallback(
    (component?: CookieBannerProClickComponent) => {
      if (isCookieBannerVisible) {
        // eslint-disable-next-line new-cap
        window.OneTrust?.RejectAll()
        setIsCookieBannerVisible(false)
        setCookiesAcceptedByUser(false)
        trackBannerSubmitted(component)
      }
    },
    [setCookiesAcceptedByUser, trackBannerSubmitted, isCookieBannerVisible]
  )

  const trackBannerShown = () => {
    if (!isCookieBannerVisible) return

    dispatchTeamEvent(
      trackEvent({
        category: Category.System,
        entity: Entity.CookieBanner,
        action: Action.Displayed,
        analyticsContext: {
          [AnalyticsContext.CookieConsentContext]: cookieConsentContext
        }
      })
    )
  }

  useOnClickOutside(ref, () => {
    if (!isPreferencesCenterVisible) {
      handleRejectAllCookies(CookieBannerProClickComponent.AppPage)
    }
  })

  useEffect(() => {
    if (window.COOKIE_PRO_LOADED) {
      handleCookieProScriptLoaded()
      return
    }

    // sometimes window.OneTrust is undefined as the script takes a while to load
    // so we should listen for the event here too
    window.addEventListener('cookieProLoaded', handleCookieProScriptLoaded)

    return () => {
      window.removeEventListener('cookieProLoaded', handleCookieProScriptLoaded)
    }
  }, [handleCookieProScriptLoaded])

  useEffect(trackBannerShown, [isCookieBannerVisible, dispatch])
  useEffect(() => {
    // CookiePro does not support en-GB nor en-US.
    const sanitizedLocale = locale.startsWith('en-') ? 'en' : locale
    window.OneTrust?.changeLanguage(sanitizedLocale)
  }, [locale])

  return (
    <AnimatePresence>
      {isCookieBannerVisible && (
        <CookieBannerWrapper
          key="CookieBannerWrapper"
          ref={ref}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={fadeInOutAndHide}
          transition={transitions.easeInOut}
        >
          <CookieProBanner
            openPreferencesCenter={openPreferencesCenter}
            acceptAllCookies={handleAcceptAllCookies}
            rejectAllCookies={handleRejectAllCookies}
          />
        </CookieBannerWrapper>
      )}
    </AnimatePresence>
  )
}
